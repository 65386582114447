import axios from '@axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCities(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/city', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCity(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/city/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteCity(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/city/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addCity(ctx, cityData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/city', cityData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchDistricts(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/district/list')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchRegions(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/region/list')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchDistrictCities(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/district/city/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
